import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: { cart: DeliveryDetails | null } = { cart: null };

const slice = createSlice({
  name: "tempcart",
  initialState,
  reducers: {
    addToTemp(state, action: PayloadAction<DeliveryDetails>) {
      state.cart = action.payload;
    },
    clearTemp(state) {
      state.cart = null;
    },
    addSupplier(state, action: PayloadAction<Builder>) {
      state.cart = { ...(state.cart as DeliveryDetails), supplier: action.payload };
    },
  },
});

export default slice.reducer;

export const { addSupplier, clearTemp, addToTemp } = slice.actions;
