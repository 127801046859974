import React, { useEffect, useState, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import "./App.less";
import "animate.css/animate.css";
import "aos/dist/aos.css";
import "./global.css";
import { MobileResizeContext } from "context/MobileResizeContext";
import useAuth from "hooks/useAuth";
import axios from "config/axios";
import NotFound from "pages/404";
import NProgress from "nprogress";
import ForgotPassword from "pages/ForgotPassword";
import ResetPassword from "pages/ForgotPassword/reset";
import PageSpinner from "components/PageSpinner";
import ApproveOrder from "pages/ApproveOrder";
import BuildMatePay from "pages/BuildMateWebview/BuildMatePay";
import VerifyBuildMatePayment from "pages/BuildMateWebview/VerifyBuildmate";
const Builder = React.lazy(() => import("pages/builders"));
const SignIn = React.lazy(() => import("pages/SignIn"));
const Proceed = React.lazy(() => import("pages/SignUp/proceed"));
const Home = React.lazy(() => import("pages"));
const UserSignUp = React.lazy(() => import("pages/SignUp/user"));

NProgress.configure({ showSpinner: false });

const handleRouteChange = (url: any) => {
  NProgress.start();
};

const handleRouteChangeComplete = (url: any) => {
  NProgress.done();
};
const removeProgressBar = (url: any) => {
  NProgress.remove();
};

export default function App() {
  const [isValidating, setIsValidating] = useState(true);
  const { logIn, updateToken, logOut } = useAuth();

  useEffect(() => {
    document.addEventListener("page:fetch", handleRouteChange);
    document.addEventListener("page:change", handleRouteChangeComplete);
    document.addEventListener("page:restore", removeProgressBar);
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("token-builder-v1");
    (async function () {
      try {
        if (token) {
          updateToken(token);
          const {
            data: { data: user },
          } = (await axios.get("user")) as { data: { data: User } };

          const {
            data: { data: builder },
          } = await axios.get(`builder/getonebuilder/${user.organizationId}`);
          logIn(user, builder.builder[0]);
        }
      } catch (error) {
        logOut();
      } finally {
        setIsValidating(false);
      }
    })();
  }, [logIn, logOut, updateToken]);
  if (isValidating) return <></>;

  return (
    <MobileResizeContext>
      <Suspense fallback={<PageSpinner />}>
        <BrowserRouter> 
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<SignIn />} />
            <Route path="/user/signup" element={<UserSignUp />} />
            <Route path="/user/signup" element={<UserSignUp />} />
            <Route path="/buildmate/payment" element={<BuildMatePay />} />
            <Route path="/signup/proceed" element={<Proceed />} />
            <Route path="/buildmate/payment" element={<BuildMatePay />} />
            <Route
              path="/buildmate/verify"
              element={<VerifyBuildMatePayment />}
            />
            <Route path="/builders/*" element={<Builder />} />

            <Route path="forgot-password" element={<ForgotPassword />} />

            <Route path="forgot-password/reset" element={<ResetPassword />} />

            <Route path="approve-order" element={<ApproveOrder />} />

            <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </Suspense>
    </MobileResizeContext>
  );
}
