import { useEffect, useState } from "react";
import requestErrorHandler from "utils/requestErrorHandler";
import { parse } from "fast-qs";
import axiosConfig from "config/axios";
import { useLocation } from "react-router";

const usePayment = () => {
  const [paystackUrl, setPaystackUrl] = useState<string>();
  const [processing, setProcessing] = useState<boolean>(false);

  const { search } = useLocation();

  const { orderId, token } = parse(search) as {
    orderId: string;
    token: string;
  };

  const createGodumpOrder = async (data: any) => {
    try {
      await axiosConfig.post(`go-dump/order`, { orderId });
      setPaystackUrl(data.authorization_url);
    } catch (error) {
      console.log("godump error " + error);
    }
  };

  const getOneOrder = async (_data?: any) => {
    try {
      const {
        data: { data },
      } = await axiosConfig.get(`homeowner/home-owner-id?orderId=${orderId}`, {
        headers: { Authorization: "Bearer " + token },
      });

      if (data?.goDumptruckOrderId) {
        createGodumpOrder(_data);
      } else {
        setPaystackUrl(_data?.authorization_url);
      }
    } catch (error) {
      console.log("getOneOrder fail: " + error);
    }
  };

  const handlePayNow = async () => {
    setProcessing(true);
    try {
      const {
        data: {
          data: { data },
        },
      } = await axiosConfig.post(
        `homeowner/pay`,
        { orderId },
        { headers: { Authorization: "Bearer " + token } }
      );
      getOneOrder(data);
    } catch (error) {
      requestErrorHandler(error);
    } finally {
      setProcessing(false);
    }
  };

  useEffect(() => {
    if (!paystackUrl) return;
    window.location.href = `${paystackUrl}`;
  }, [paystackUrl]);

  return {
    handlePayNow,
    processing,
  };
};

export default usePayment;
