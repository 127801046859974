import usePayment from "hooks/usePayment";
import React, { useEffect } from "react";

function BuildMatePay() {
  const { handlePayNow } = usePayment();

  useEffect(() => {
    handlePayNow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        paddingInline: "2rem",
        display: "grid",
        placeContent: "center",
      }}
    >
      Initializing Payment...
    </div>
  );
}

export default BuildMatePay;
