import AuthFormShared, {
  AuthButton,
} from "components/AuthFormShared/AuthFormShared";
import { Button, Form, Input, message, Row } from "antd";
import { useEffect, useState } from "react";
import useAuth from "hooks/useAuth";
import styles from "./signIn.module.css";
import { ArrowLeftOutlined } from "@ant-design/icons";
import axiosConfig from "config/axios";
import { useNavigate } from "react-router";
import requestErrorHandler from "utils/requestErrorHandler";

export default function ForgotPassword(props: any) {
  const [form] = Form.useForm();
  // const [isLoading] = useState(false);
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<String>("");
  const [isSuccessful, setIsSuccessful] = useState<boolean>();
  const navigate = useNavigate();

  async function handleSubmit(values: any) {
    setEmail(values.email);
    if (isLoading) return;
    setIsLoading(true);
    try {
      await axiosConfig.post("user/forgotpassword", {
        email: String(values.email).toLowerCase(),
        type: "builder",
      });
      message.success("Email submitted Successfully");
      setIsLoading(false);
      setIsSuccessful(true);
    } catch (error: any) {
      requestErrorHandler(error);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (user) {
      navigate("/builders/buy");
    }
  }, []);

  if (isSuccessful) {
    return (
      <div className={styles.container}>
        <div className={styles["confirm-email-wrapper"]}>
          <div className={styles["email-confirm-variant"]}>
            <div className={styles["email-circle"]} />
            <div className={styles["email-circle2"]} />
            <img
              src="https://materialspro-media.s3.us-west-1.amazonaws.com/email-confirm2.png"
              alt="email-confirm"
              width={250}
            />
            <p>
              A Verification email has been sent to <b>{email}</b>
              <span>kindly check your email to complete the process. </span>
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles["auth-form"]}>
            <Row>
              <Button type="link" onClick={() => navigate("/login")}>
                <ArrowLeftOutlined color="primary" />
              </Button>
            </Row>
            <AuthFormShared title="Forgot password" {...props}>
              <div className="w-100">
                <Form onFinish={handleSubmit} form={form} layout="vertical">
                  <Form.Item
                    name="email"
                    label="Email Address"
                    rules={[{ required: true }, { type: "email" }]}
                  >
                    <Input size="large" />
                  </Form.Item>

                  <AuthButton isLoading={isLoading}>SUBMIT</AuthButton>
                </Form>
              </div>
            </AuthFormShared>
          </div>
        </div>
      </div>
    </>
  );
}
