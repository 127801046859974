import { createContext, useState, ReactNode, useLayoutEffect } from "react";
import debounce from "lodash.debounce";

const DeviceWidth = createContext<boolean>(false);

export const MobileResizeContext = ({ children }: { children: ReactNode }) => {
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const checkDeviceWidth = debounce(
    () => {
      if (window.innerWidth < 900) {
        return setIsMobile(true);
      }
      return setIsMobile(false);
    },
    1000,
    {
      leading: true,
    }
  );

  useLayoutEffect(() => {
    checkDeviceWidth();
    window.addEventListener("resize", checkDeviceWidth);
    return () => {
      window.removeEventListener("resize", checkDeviceWidth);
    };
  });
  return (
    <DeviceWidth.Provider value={isMobile}>{children}</DeviceWidth.Provider>
  );
};

export default DeviceWidth;
