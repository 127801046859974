import styles from "./styles.module.css";
import logo from "assets/logo/logo.png";
import classNames from "classnames";
import { Form, Button } from "antd";
import { ReactComponent as Spinner } from "assets/icons/Spinner.svg";
import { useEffect } from "react";
type AuthFormSharedProps = {
  title: string;
  children: React.ReactNode;
};

export default function AuthFormShared({
  title,
  children,
}: AuthFormSharedProps) {
  useEffect(() => {
    const oldTitle = document.title;
    document.title = title;
    return () => {
      document.title = oldTitle;
    };
  }, [title]);
  return (
    <div>
      <div className={styles["auth-container"]}>
        <img src={logo} alt="logo" className={classNames([styles.logo])} />
        <div className={styles.form}>
          <h3>{title}</h3>
        </div>
        <div className={styles.form__div}>{children}</div>
        <footer>
          Copyright © {new Date().getFullYear().toString()} MaterialsPro
        </footer>
      </div>
    </div>
  );
}

type AuthButtonProps = {
  children: React.ReactNode;
  isLoading?: boolean;
  disabled?: boolean;
};

export function AuthButton({ children, isLoading, disabled }: AuthButtonProps) {
  return (
    <Form.Item>
      <Button
        type="primary"
        block
        htmlType="submit"
        size="large"
        disabled={disabled}
      >
        {isLoading ? (
          <Spinner className={styles["auth-button__loading"]} />
        ) : (
          children
        )}
      </Button>
    </Form.Item>
  );
}
