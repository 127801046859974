import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type AuthState = {
  token: string | null;
  user: null | User;
  builder: null | Builder;
};

const initialState: AuthState = {
  user: null,
  token: null,
  builder: null,
};

const slice = createSlice({
  initialState,
  name: "auth",
  reducers: {
    addToken(state, action: PayloadAction<string>) {
      state.token = action.payload;
    },
    signIn(state, action: PayloadAction<{ user: User; builder: Builder }>) {
      state.user = action.payload.user;
      state.builder = action.payload.builder;
    },
    signOut(state) {
      state.token = null;
      state.user = null;
      state.builder = null;
    },
  },
});

export default slice.reducer;
export const { addToken, signIn, signOut } = slice.actions;
