import { Button, message } from "antd";
import styles from "./approve.module.css";
import { parse } from "fast-qs";
import { useEffect, useState } from "react";
import { ArrowRightOutlined } from "@ant-design/icons";
import checked from "../../assets/checked.png";
import declined from "../../assets/declined.png";
import axiosConfig from "config/axios";
import logo from "assets/logo/logo.png";
import PageSpinner from "components/PageSpinner";
import { useLocation, useNavigate } from "react-router";
import requestErrorHandler from "utils/requestErrorHandler";

export default function ApproveOrder() {
  const [verified, setVerified] = useState<boolean>();
  // const { user } = useAuth();
  const { search } = useLocation();
  const navigate = useNavigate();
  const [orderApproved, setOrderApproved] = useState<boolean>();

  const { type, email, token, orderId, userId } = parse(
    search.replaceAll("?", "&")
  ) as {
    type: string;
    email: string;
    token: string;
    orderId: string;
    userId: string;
  };

  const approveOrder = async () => {
    await axiosConfig
      .post("order/approveorder", {
        orderId,
        userId,
      })
      .then((res) => {
        setOrderApproved(true);
        message.success("Order Approved");
      })
      .catch((error) => {
        setOrderApproved(false);
        requestErrorHandler(error);
      });
  };

  const declineOrder = async () => {
    await axiosConfig
      .post("order/cancelorder", {
        orderId,
        userId,
      })
      .then((res) => {
        setOrderApproved(true);
        message.success("Order Canceled");
      })
      .catch((error) => {
        setOrderApproved(false);
        requestErrorHandler(error);
      });
  };
  useEffect(() => {
    axiosConfig
      .post(`verifytoken`, {
        email,
        token,
      })
      .then((data) => {
        setVerified(true);
        if (type === "approve") {
          approveOrder();
        } else if (type === "reject") {
          declineOrder();
        }
      })
      .catch((error) => {
        requestErrorHandler(error);
        setVerified(false);
        navigate("/login");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (verified === undefined)
    return (
      <div className={styles["confirm-email-wrapper"]}>
        <div className={styles.container}>
          <div className={styles.content}>
            <PageSpinner />
          </div>
        </div>
      </div>
    );
  if (verified === false) {
    navigate("../404");
    return <></>;
  }

  if (orderApproved === undefined) {
    return (
      <div className={styles["confirm-email-wrapper"]}>
        <div className={styles.container}>
          <div className={styles.content}>
            <PageSpinner />
          </div>
        </div>
      </div>
    );
  }

  // if (user) return <Redirect from="/" to="/builders/buy" noThrow />;

  return (
    <div className={styles.container}>
      <img src={logo} alt="logo" className={styles.logo} />
      <div className={styles.content}>
        {orderApproved ? (
          <>
            <img
              className={styles.img}
              src={checked}
              alt="checked"
              width="200"
            />
            <Button type="primary" href="/" size="middle">
              Proceed
              <ArrowRightOutlined />
            </Button>
          </>
        ) : (
          <>
            <img
              className={styles.img}
              src={declined}
              alt="checked"
              width="200"
            />
            <Button type="primary" href="/" size="middle">
              Proceed
              <ArrowRightOutlined />
            </Button>
          </>
        )}
      </div>
    </div>
  );
}
